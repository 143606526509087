//-----navbar-----//
$baseColor: #643a24;
$secondaryColor: #d09a49;
$topNavTextColor: #ffffff;
$bottomNavtextColor: #000f41;
$bottomNavInnerTextColor: black;
$navElementBorderColor: #d09a49;
$navElementHoverColor: #643a24;
$mobileNavElementBackground: #fae1cb;
$mobileNavInnerElementBackground: #fef4eb;

//-----slider-----//
$baseDotColor: #643a24;
$activeDotColor: #d09a49;

//------home/basic page button-----------//
$buttonBackgroundColor: #643a24;
$buttonHoverBackgroundColor: #d09a49;
$buttonHoverTextColor: black;
$buttonTextColor: #ffffff;

//breadcrumb
$breadcrumbTextColor: #643a24;
$headingsColor: #d09a49;

//-----home page variables ----//
$servicesBackground: #88645625;
$happeningsBackground: #d6cfc935;
$headingTextColor: #643a24;
$newsBarColor: #d1994a;

//-----footer varibales----//
$footerTopBackground: #d1994a;
$footerMiddleBackground: #886456;
$footerBottomBackground: #643a24;
